import { useState } from "react";
import { Redirect } from "react-router-dom";

import { useAuth } from "../context/auth";
import axios from "axios";

import styles from "./styles.module.scss";

function Login(props) {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthTokens } = useAuth();

  const referer = props.location?.state?.referer || "/";

  function toFormData(data) {
    const tmp = new FormData();
    Object.keys(data).forEach((key) => {
      tmp.append(key, data[key]);
    });
    return tmp;
  }

  function postLogin(e) {
    e.preventDefault();
    axios
      .post(
        "https://apimep.gbc-algeria.com/auth/login.php",
        toFormData({
          username,
          password,
        })
      )
      .then((result) => {
        if (result.status === 200) {
          setAuthTokens(result.data);
          setLoggedIn(true);
          // setAuthTokens(result.data);
        } else {
          setIsError(true);
        }
      })
      .catch(() => {
        setIsError(true);
      });
  }

  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }

  return (
    <div className={styles.limiter}>
      <div className={styles.containerLogin100}>
        {isError && (
          <span style={{ color: "red" }}>
            The username or password provided were incorrect!
          </span>
        )}
        <div className={styles.wrapLogin100}>
          <form autoComplete="off" className={styles.loginForm}>
            <div className={styles.logo}>
              <img src="img/logo_gbsystem.png" alt="Logo GB system" />
            </div>
            <div className={styles.wrapInput100}>
              <input
                type="text"
                className={styles.inputW}
                placeholder="Username"
                autoComplete="off"
                value={username}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </div>
            <div className={styles.wrapInput100}>
              <input
                type="password"
                className={styles.inputW}
                placeholder="Password"
                autoComplete="off"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <p>Mot de passe oublié?</p>
            <div className={styles.containerFormLoginBtn}>
              <button onClick={postLogin} className={styles.login100Btn}>
                S'identifier
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.footer}>
        <div>
          <img src="img/logo_nsis.png" alt="Logo NSIS" className="inline" />
          <img src="img/logo_gbit.png" alt="Logo GBit" className="inline" />
          <p>Copyright © 2021 GBit</p>
        </div>
      </div>
    </div>
  );
}

export default Login;
