import { ReactNode } from "react";
import cx from "classnames";
import { ReactComponent as ProjectIcon } from "../../../assets/svg/pm.svg";
import styles from "../styles.module.scss";

interface IProps {
  url: string;
  isDragging: boolean;
  icon?: ReactNode;
  title: string;
  subTitle: string;
  backgroundColor?: string;
  disabled: boolean;
}

const DEFAULT_ICON = <ProjectIcon height={80} width={80} />;

export default function Widget({
  url,
  isDragging = false,
  icon = DEFAULT_ICON,
  title = "Title",
  subTitle = "subTitle",
  backgroundColor = "#1973bb",
  disabled = false,
}: IProps) {
  console.log(disabled);
  const _handleClick = (e: any) => {
    //if (isDragging && !disabled) {
      global.location.replace(url);
    //}
  };

  const classes = cx({
    [styles.boxItem]: true,
    [styles.grow]: !disabled,
    [styles.disabled]: disabled,
  });

  return (
    <div className={classes} onClick={_handleClick}>
      <div
        className={styles.cardContainer}
        style={{ backgroundColor: disabled ? "#cecece" : backgroundColor }}
      >
        <div className={styles.iconWrapper}>{icon}</div>
        <div>
          <h4 className={styles.cardTitle}>{title}</h4>
          <h6 className={styles.cardSubtitle}>{subTitle}</h6>
        </div>
      </div>
    </div>
  );
}
