import styles from "../styles.module.scss";

interface ICardProps {
  url?: string;
  children: React.ReactNode;
  backgroundColor?: string;
  isDragging: boolean;
  disabled?: boolean;
}

export function Card({
  children,
  backgroundColor = "inherit",
  isDragging = false,
  url = "#",
  disabled = false,
}: ICardProps) {
  const _handleClick = (e: any) => {
    if (isDragging) {
      window.location.href = url;
     // global.location.replace(url);
    }
  };

  return (
    <div className={`${styles.boxItem} ${styles.grow}`} onClick={_handleClick}>
      <div className={styles.cardContainer} style={{ backgroundColor }}>
        {children}
      </div>
    </div>
  );
}
