import React, { useEffect, useState } from "react";
import { HashRouter as Router, Link, Redirect, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";


import { AuthContext } from "./context/auth";
import Login from "./pages/Login";
import Applauncher from "./pages/AppLauncher";

function App(props) {
  const existingTokens =
    localStorage.getItem("@auth.userdata") !== undefined
      ? localStorage.getItem("@auth.userdata")
      : "{}";
  const [authTokens, setAuthTokens] = useState(JSON.parse(existingTokens));

  const setTokens = (data) => {
    console.log(data);
    localStorage.setItem("@auth.userdata", JSON.stringify(data));

    localStorage.setItem("@auth.token", JSON.stringify(data?.token));
    setAuthTokens(data);
  };

  useEffect(() => {
    //setAuthTokens(JSON.parse(window.localStorage.getItem("@auth.userdata") || "{}"));
  }, []);
  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <Route exact path="/">
          <Redirect to="/admin" />
        </Route>
        <Route path="/login" component={Login} />
        {/* <Route path="/signup" component={Signup} /> */}
        <PrivateRoute path="/admin" component={Applauncher} />
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
