import styles from "../styles.module.scss";
import { ReactComponent as CardIcon } from "../../../assets/svg/gc.svg";
import { Card } from "./Card";

interface IProps {
  isDragging: boolean;
}
var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export default function DateCard({ isDragging }: IProps) {
  return (
    <Card backgroundColor="#0747a6" isDragging={isDragging}>
      <div className={styles.iconWrapper}>
        <CardIcon height={40} width={40} />
        <h6 className={styles.day}>{new Date().getDate()}</h6>
      </div>
      <div className={styles.cardDescription} style={{ alignSelf: "center" }}>
        <h6 style={{ color: "white" }}>{mL[new Date().getMonth()]}</h6>
      </div>
    </Card>
  );
}
